import { all, takeLatest } from "redux-saga/effects";
import { successMessage } from "../../utilities/notification";
import {
  CREATE_PLAN,
  DELETE_PLAN,
  GET_PLANS,
  UPDATE_PLAN,
} from "../actions/plan.actions.types";
import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* createPlan({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "admin/subscription/plan",
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });
  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    successMessage(result.message);
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* deletePlan({ payload: { callback, planId } }) {
  const payload = {
    method: "delete",
    url: `admin/subscription/plan/${planId}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: error });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* updatePlan({ payload: { callback, planId, data } }) {
  const payload = {
    data,
    method: "patch",
    url: `admin/subscription/plan/${planId}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* getPlans({ payload: { callback } }) {
  const payload = {
    method: "get",
    url: `admin/subscription/plan`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result.data });
    }
  }
}

function* Plans() {
  yield all([takeLatest(CREATE_PLAN, createPlan)]);
  yield all([takeLatest(GET_PLANS, getPlans)]);
  yield all([takeLatest(UPDATE_PLAN, updatePlan)]);
  yield all([takeLatest(DELETE_PLAN, deletePlan)]);
}

export default Plans;
