import { createSlice } from "@reduxjs/toolkit";
const initialState = { company: null };

export const companyslice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state = initialState, { payload }) => {
      return {
        ...state,
        company: payload,
      };
    },
  },
  extraReducers: {},
});
