import { combineReducers } from '@reduxjs/toolkit';
import { appslice } from './app';
import { userslice } from './user';
import { companyslice } from './company';
import { planslice } from './plan';
import createCompressor from 'redux-persist-transform-compress';
import encryptor from './encryptor';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const reducers = combineReducers({
  user: userslice.reducer,
  app: appslice.reducer,
  company: companyslice.reducer,
  plan: planslice.reducer,
});

const resetRootReducer = (state, action) => {
  if (action.type === 'user/logOutAction') {
    return reducers({user:{}, app:{}, company:{}, plan:{}, setting:{}}, action);
  }
  return reducers(state, action);
}
const compressor = createCompressor();

const config = {
  blacklist: ['app', 'network', 'toast'],
  key: 'primary-1',
  storage,
  transforms: [encryptor, compressor],
};

export const persistedReducer = persistReducer(config, resetRootReducer);
